/* eslint-disable no-underscore-dangle */
import {
  FC,
  memo,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useRouter } from 'next/router';
import cx from 'clsx';
import type {
  DatoProduct,
  DatoProductImage,
  CustomCard,
} from '@framework/api/types';
import GTM from '@utils/gtm';
import slugify from '@utils/slugify';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import ProductCard from '@components/ProductCard';
import DummyProductCard from '@components/DummyProductCard';
import EditorialCard from '@components/EditorialCard';
import SectionHeading from '@components/SectionHeading';
import * as styles from './styles';

type Props = {
  collectionName: string;
  eyebrowLogo?: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  currencyCode: string;
  products: DatoProduct[] | CustomCard[];
  columnCount?: number;
  isExtendedCard?: boolean;
  textPosition?: string;
  blockType?: string;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  backgroundColor?: string;
  shouldTrack?: boolean;
};

const ProductCollectionGrid: FC<Props> = ({
  collectionName,
  products,
  currencyCode,
  eyebrowLogo,
  heading,
  subheading,
  eyebrow,
  columnCount,
  isExtendedCard,
  textPosition,
  blockType,
  setIsLoading,
  backgroundColor,
  shouldTrack,
}) => {
  const { locale } = useRouter();

  const [hasBeenTrackedInGA, setHasBeenTrackedInGA] = useState(null);

  // track product impressions for GA enhanced ecommerce
  useEffect(() => {
    let timeout = null;

    if (shouldTrack && Array.isArray(products) && products.length > 0) {
      const items = [];

      const onlyProds = (products as DatoProduct[]).filter(
        p => p._modelApiKey === 'product'
      );

      onlyProds.forEach((product, i) => {
        if (product._modelApiKey === 'product') {
          items.push({
            item_name: product.name,
            item_list_name: `${collectionName} Collection Page List`,
            item_list_id: slugify(`${collectionName} Collection Page List`),
            item_id:
              Array.isArray(product.variants) && product.variants.length > 0
                ? (
                    product.variants.find(variant => variant.stock > 0) ||
                    product.variants[0]
                  ).sku
                : product.slug,
            price: product.price ? parseFloat(product.price).toString() : null,
            brand: 'NZXT',
            variant:
              Array.isArray(product.variants) &&
              product.variants.length > 0 &&
              Array.isArray(product.variants[0].color)
                ? product.variants[0].color.map(color => color.name).join('/')
                : null,
            position: i,
          });
        }
      });

      if (collectionName !== hasBeenTrackedInGA) {
        timeout = setTimeout(() => {
          GTM.dataLayer({
            dataLayer: {
              event: 'view_item_list',
              ecommerce: {
                item_list_name: `${collectionName} Collection Page List`,
                item_list_id: slugify(`${collectionName} Collection Page List`),
                items,
              },
            },
          });
          setHasBeenTrackedInGA(collectionName);
        }, 1);
      }
    }
    return () => clearTimeout(timeout);
  }, [hasBeenTrackedInGA, products, currencyCode, collectionName, shouldTrack]);

  const productsLength = Array.isArray(products) && products?.length;
  const hasProducts = Boolean(Array.isArray(products) && products?.length > 0);
  const hasFewerItemsThanCol = productsLength < columnCount;

  const composedClassName = useMemo(
    () =>
      cx(
        hasFewerItemsThanCol
          ? styles.fewerItems[productsLength]
          : styles.getListWrapper(columnCount)
      ),
    [columnCount, hasFewerItemsThanCol, productsLength]
  );

  const isEven = (products?.length || 0) % 2 === 0;

  return hasProducts ? (
    <SectionWrapper
      id={slugify(heading)}
      data-test-id={blockType}
      style={{ backgroundColor }}
      className="scroll-m-20"
    >
      <ContentWrapper>
        <SectionHeading
          heading={heading}
          eyebrowLogo={eyebrowLogo}
          eyebrow={eyebrow}
          subheading={subheading}
          lang={locale}
          textPosition={textPosition}
          darkenSubheading={Boolean(backgroundColor)}
        />

        <div className={composedClassName}>
          {products.map((product, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${product.id}-${i}`}
              className={styles.cardSizeWrapper(
                product?._modelApiKey === 'editorial_card' &&
                  products.length - 1 === i &&
                  !isEven
              )}
            >
              {product?._modelApiKey === 'product' && (
                <ProductCard
                  collectionImage={product.collectionPageMainImage}
                  name={product.name}
                  slug={product.slug}
                  rating={product.variants[0]?.shopify_parent_id}
                  variants={product.variants}
                  productPrice={product.collectionPagePrice}
                  currencyCode={currencyCode}
                  shortDescription={product.shortDescription}
                  availableRegions={product.availableRegions}
                  techSpecs={product.techSpecs}
                  isExtendedCard={isExtendedCard}
                  regionalServices={product?.regionalServices || []}
                  noVariantToggle
                  isSmall
                  setIsLoading={setIsLoading}
                  hasBackground={Boolean(backgroundColor)}
                  isDark={product?.isPrime}
                />
              )}
              {product?._modelApiKey === 'custom_card' && (
                <DummyProductCard
                  name={product.title}
                  slug={product.buttonLink}
                  shortDescription={product.shortDescription}
                  extendedHeading={product.subsectionTitle}
                  extendedDetails={[product.subsectionDetails]}
                  ctaTitle={product.ctaTitle}
                  collectionImage={product.mainAsset}
                  isExtendedCard={isExtendedCard}
                  isSmall
                />
              )}
              {product?._modelApiKey === 'editorial_card' && (
                <EditorialCard
                  isInverted={product?.isInverted}
                  contentHorizontalAlignment={
                    product?.contentHorizontalAlignment
                  }
                  contentVerticalAlignment={product?.contentVerticalAlignment}
                  eyebrowLogo={product?.eyebrowLogo}
                  heading={product?.heading}
                  subheading={product?.subheading}
                  internalLink={product?.internalLink}
                  backgroundImage={product?.backgroundImage}
                  backgroundImageAlignment={product?.backgroundImageAlignment}
                  backgroundColor={product?.backgroundColor}
                  gradientColor={product?.gradientColor}
                  buttonLabel={product?.buttonLabel}
                  eventType="productGridClickEditorialCard"
                  useLeadText={product?.leadTextStyles}
                  alwaysTall={columnCount === 4}
                  useDoubleWide={
                    process.env.NEXT_PUBLIC_FEATURE_WIDE_EDITORIAL_CARD ===
                      'true' &&
                    products.length - 1 === i &&
                    !isEven
                  }
                />
              )}
            </div>
          ))}
        </div>
      </ContentWrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(ProductCollectionGrid);
